import React, { useEffect, useLayoutEffect, useRef } from 'react';
import cx from 'classnames';
import {
  eachDayOfInterval,
  format,
  isSameDay,
  isWithinInterval,
  startOfWeek,
  endOfWeek,
  startOfMonth,
} from 'date-fns';

import { MenuLeftIcon, MenuRightIcon } from '@mc/wink-icons';
import IconButton from '../IconButton';
import { ariaDescribedByIds } from '../utils';
import useCalendar, { getDaysInMonth } from './useCalendar';
import {
  TranslatedMonths,
  TranslateCalendar,
  TranslatedDays,
} from './TranslateCalendar';
import stylesheet from './Calendar.less';

export type DateFilter = (e: Date) => boolean;

export type CalendarProps = {
  /** Optional prop to provide a descriptive label for screen readers. */
  'aria-describedby': string;
  /** Optional function to arbitrarily disable dates for selection. */
  dateFilter?: DateFilter;
  /** Setting this to true assumes that value is an array that can contain a start and end date.
   * This prop should be considered static and not change between renders.
   */
  isRange?: boolean;
  /** An optional, upper bound for selectable dates. */
  max?: Date | number;
  /** An optional, lower bound for selectable dates. */
  min?: Date | number;
  /** The callback to fire when the user selects a new date. */
  onChange: (e: [Date, Date | null] | Date) => void;
  /** Value for start day of week */
  startDayOfWeek?:
    | 'Sunday'
    | 'Monday'
    | 'Tuesday'
    | 'Wednesday'
    | 'Thursday'
    | 'Friday'
    | 'Saturday';
  /** The value for the field. */
  value?: Date[] | Date;
  onKeyDown?: (e: React.KeyboardEvent) => void;
};

type TranslatedDaysType = string[];
type TranslatedDaysMapType = {
  [key: string]: string;
};

// Constructs some structural data we use to build out the
// calendar view. Might be worth pre-computing states here
// if we want to pass in the filter + min/max.
function getRows(
  year: number,
  month: number,
  startDayOfWeek = 0,
  includeLastRow = true,
) {
  let firstDayOffset;
  const firstDayOfMonth = new Date(year, month).getDay();
  const firstDayOfWeekIndex = startOfMonth(new Date(year, month)).getDay();

  // THis will calculate how much to offset the first day of week.
  // Prevents negatives and zero from appearing on the calendar.
  if (startDayOfWeek === firstDayOfWeekIndex) {
    firstDayOffset = 0;
  } else if (firstDayOfMonth - startDayOfWeek < 0) {
    firstDayOffset = 7 + (firstDayOfMonth - startDayOfWeek);
  } else {
    firstDayOffset = firstDayOfMonth - startDayOfWeek;
  }

  const dayCount = getDaysInMonth(year, month);
  const prevDayCount = getDaysInMonth(year, month - 1);
  const rows = [];

  outer: for (let row = 0; row < 6; row++) {
    const rowData = [];
    for (let col = 0; col < 7; col++) {
      const cellIndex = row * 7 + col;

      if (
        !includeLastRow &&
        col === 0 &&
        cellIndex + 1 - firstDayOffset > dayCount
      ) {
        // handle the case of the needless row
        break outer;
      } else if (row === 0 && cellIndex < firstDayOffset) {
        // prev month
        const date = prevDayCount - firstDayOffset + col + 1;
        rowData.push({
          date,
          monthOffset: -1,
          instance: new Date(year, month - 1, date, 0, 0, 0),
        });
      } else if (cellIndex - firstDayOffset + 1 > dayCount) {
        // following month
        const date = ((cellIndex - firstDayOffset) % dayCount) + 1;
        rowData.push({
          date,
          monthOffset: 1,
          instance: new Date(year, month + 1, date, 0, 0, 0),
        });
      } else {
        // current month
        const date = cellIndex + 1 - firstDayOffset;
        rowData.push({
          date,
          monthOffset: 0,
          instance: new Date(year, month, date, 0, 0, 0),
        });
      }
    }
    rows.push(rowData);
  }

  return rows;
}

// This function is used to serialize the date into a single
// format, pass it to the DOM and later query it for focusing.
function serialize(date: Date) {
  return format(date, 'MM-dd-yyyy');
}

// Only used for determining the startDateIndex when
// startDayOfWeek is English
const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const Calendar = React.forwardRef<HTMLDivElement, CalendarProps>(
  function Calendar(
    {
      dateFilter = () => true,
      isRange = false,
      max,
      min,
      onChange,
      value,
      startDayOfWeek = days[0],
      'aria-describedby': ariaDescribedbyId,
      ...props
    },
    forwardedRef,
  ) {
    // Translate months
    const months = TranslatedMonths();

    // Translate days
    const translatedDays: TranslatedDaysType = TranslatedDays();

    // Map for handling when start of week is not Sunday (seems rare)
    const translatedDaysMap: TranslatedDaysMapType = {
      Sunday: translatedDays[0],
      Monday: translatedDays[1],
      Tuesday: translatedDays[2],
      Wednesday: translatedDays[3],
      Thursday: translatedDays[4],
      Friday: translatedDays[5],
      Saturday: translatedDays[6],
    };

    let startValue = value;
    let endValue: Date;

    function getDayIndex(day: string) {
      return days.includes(day)
        ? days.indexOf(day)
        : translatedDays.indexOf(day);
    }

    function getWeek(date: Date, startDay: string) {
      const startDateIndex = getDayIndex(startDay);
      const start = startOfWeek(date, {
        weekStartsOn: startDateIndex as 0 | 1 | 2 | 3 | 4 | 5 | 6,
      });
      const end = endOfWeek(date, {
        weekStartsOn: startDateIndex as 0 | 1 | 2 | 3 | 4 | 5 | 6,
      });
      const dates = eachDayOfInterval({ start, end });

      return dates;
    }

    function daysOfWeekOrder(startDay: string) {
      const week = getWeek(new Date(), startDay);
      const weekDays: string[] = [];
      week.forEach((day) => {
        weekDays.push(format(day, 'EEEE'));
      });
      const translatedWeekDays = weekDays.map((day) => translatedDaysMap[day]);

      return translatedWeekDays;
    }

    // if isRange is defined, default value to an array and destructure start and end dates
    if (isRange) {
      value = Array.isArray(value) ? value : [];
      [startValue, endValue] = value;
    }

    const [
      { focusedDate, calendarMonthInView },
      {
        prevMonth,
        nextMonth,
        moveFocusByDay,
        moveFocusByMonth,
        setFocusedDate,
        moveFocusToFirstDateOfCurrentMonth,
        moveFocusToLastDateOfCurrentMonth,
        moveFocusToFirstDayOfFocusedWeek,
        moveFocusToLastDayOfFocusedWeek,
      },
    ] = useCalendar({ initialValue: startValue as Date, min, max, dateFilter });

    const tableRef = useRef<HTMLTableElement | null>(null);

    const yearInView = calendarMonthInView.getFullYear();
    const monthInView = calendarMonthInView.getMonth();

    // Translation for default text
    const { prevMonthText, nextMonthText, selectedText, currentMonthText } =
      TranslateCalendar({
        month: months[monthInView],
        year: yearInView,
      });

    // Handle upstream value changes to focus the selected date
    useEffect(() => {
      setFocusedDate(startValue as Date);
    }, [setFocusedDate, startValue]);

    // Sync focus whenever `focusedDate` or `calendarMonthInView` change
    useLayoutEffect(() => {
      const table = tableRef.current;

      const { activeElement } = document;

      // Don't want to steal focus
      if (activeElement === null) {
        return;
      }

      // Only steal "local" focus. This means that if an element outside
      // the calendar grid is focused, don't steal focus from it. The one
      // exception to this rule is when the element in question is the
      // body element. When using the keyboard to traverse the boundary of
      // a month, React will flush an update to the DOM that removes the
      // currently-focused button. When this happens, the body element
      // will receive focus. We specifically check for this in order to
      // restore focus on the targeted button element in the new set of
      // buttons.
      if (
        table &&
        !table.contains(activeElement) &&
        activeElement !== document.body
      ) {
        return;
      }

      if (focusedDate) {
        const element: HTMLButtonElement | null | undefined =
          table?.querySelector(`[data-date="${serialize(focusedDate)}"]`);

        if (element && element !== null && document.activeElement !== element) {
          element.focus();
        }
      }
    }, [focusedDate, calendarMonthInView, forwardedRef]);

    const handleChange = (date: Date) => {
      if (!isRange) {
        return onChange(date);
      }

      if (!startValue || date <= startValue || endValue) {
        return onChange([date, null]);
      }

      return onChange([startValue as Date, date]);
    };

    return (
      <div
        className={stylesheet.root}
        ref={forwardedRef}
        tabIndex={-1}
        role="dialog"
        aria-label={currentMonthText}
        {...props}
      >
        <div className={stylesheet.month}>
          <IconButton
            icon={<MenuLeftIcon />}
            onClick={prevMonth}
            label={prevMonthText}
          />
          {months[monthInView]} {yearInView}
          <IconButton
            icon={<MenuRightIcon />}
            onClick={nextMonth}
            label={nextMonthText}
          />
        </div>
        <table
          ref={tableRef}
          className={stylesheet.calendar}
          role="grid"
          onKeyDown={(e) => {
            switch (e.key) {
              case 'ArrowLeft':
                moveFocusByDay(-1);
                e.preventDefault();
                break;
              case 'ArrowRight':
                moveFocusByDay(1);
                e.preventDefault();
                break;
              case 'ArrowUp':
                moveFocusByDay(-7);
                e.preventDefault();
                break;
              case 'ArrowDown':
                moveFocusByDay(7);
                e.preventDefault();
                break;
              case 'Home':
                if (e.ctrlKey) {
                  moveFocusToFirstDateOfCurrentMonth();
                } else {
                  moveFocusToFirstDayOfFocusedWeek();
                }
                e.preventDefault();
                break;
              case 'End':
                if (e.ctrlKey) {
                  moveFocusToLastDateOfCurrentMonth();
                } else {
                  moveFocusToLastDayOfFocusedWeek();
                }
                e.preventDefault();
                break;
              case 'PageUp':
                moveFocusByMonth(-1);
                e.preventDefault();
                break;
              case 'PageDown':
                moveFocusByMonth(1);
                e.preventDefault();
                break;
              case 'Escape':
                if (
                  focusedDate &&
                  startValue &&
                  !isSameDay(
                    focusedDate,
                    startValue instanceof Date ? startValue : focusedDate,
                  )
                ) {
                  setFocusedDate(startValue);
                  e.preventDefault();
                }
                break;
              default:
                break;
            }
          }}
        >
          <thead>
            <tr>
              {daysOfWeekOrder(startDayOfWeek).map((day) => (
                <th key={day}>
                  {/* The visually hidden item expands the visual size of VoiceOver's cursor.
                This is purely visual and should not effect screen reader usage. */}
                  <span className="wink-visually-hidden">{day}</span>
                  <span aria-hidden="true">{day.substr(0, 1)}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {getRows(yearInView, monthInView, getDayIndex(startDayOfWeek)).map(
              (week, index) => (
                <tr key={index}>
                  {week.map((date) => {
                    const serializedDate = serialize(date.instance);
                    const isStart = isSameDay(
                      startValue instanceof Date ? startValue : 1,
                      date.instance,
                    );
                    const isEnd = isSameDay(endValue, date.instance);
                    const isPressed = isStart || isEnd;
                    const isWithinRange =
                      startValue &&
                      endValue &&
                      isWithinInterval(date.instance, {
                        start: startValue instanceof Date ? startValue : 1,
                        end: endValue,
                      });
                    const isTabbable = isSameDay(focusedDate, date.instance);

                    const humanReadableDate = format(
                      date.instance,
                      'd MMMM yyyy',
                    );

                    const ariaLabel = isPressed
                      ? `${selectedText} ${humanReadableDate}.`
                      : humanReadableDate;

                    const isDisabled =
                      // Disable out-of-month days
                      date.monthOffset !== 0 ||
                      // Dates who exceed the bounds of min/max
                      (min !== undefined &&
                        // JS dates are truly a nightmare
                        !isSameDay(min, date.instance) &&
                        min > date.instance) ||
                      (max !== undefined && max < date.instance) ||
                      // Dates that don't pass the filter
                      !dateFilter(date.instance);

                    return (
                      <td
                        key={serializedDate}
                        className={cx({
                          [stylesheet.isWithinRange]: isWithinRange,
                        })}
                      >
                        <button
                          type="button"
                          tabIndex={isTabbable ? undefined : -1}
                          aria-pressed={isPressed}
                          aria-label={ariaLabel}
                          // We use aria-disabled here instead of the regular
                          // disabled attribute as we want to be able to
                          // programmatically focus any of the date buttons.
                          aria-disabled={isDisabled}
                          data-date={serializedDate}
                          aria-describedby={ariaDescribedByIds(
                            ariaDescribedbyId,
                          )}
                          onMouseDown={(e) => {
                            // Don't focus disabled buttons from clicks
                            if (isDisabled) {
                              e.preventDefault();
                            }
                          }}
                          onClick={() => {
                            if (!isDisabled) {
                              handleChange(date.instance);
                            }
                          }}
                        >
                          {date.date}
                        </button>
                      </td>
                    );
                  })}
                </tr>
              ),
            )}
          </tbody>
        </table>
      </div>
    );
  },
);

export default Calendar;
