import React, { useRef, useState, useEffect, HTMLAttributes } from 'react';
import tabbable from 'tabbable';

import { MenuDownIcon } from '@mc/wink-icons';
import cx from 'classnames';
import chainHandlers from '@mc/fn/chainHandlers';
import useJoinedRef from '@mc/hooks/useJoinedRef';
import useId from '@mc/hooks/useId';

import Animate from '../Animate';
import Button from '../Button';
import IconButton from '../IconButton';
import Popup from '../Popup';
import TextButton from '../TextButton';

import ActionListItem from './ActionListItem';
import stylesheet from './ActionList.less';

export type ActionListMenuProps = {
  /** Trigger reference */
  buttonRef?: React.MutableRefObject<HTMLElement | null>;
  /** Consumes `ActionListItem` components */
  children?: React.ReactNode;
  /** Current state on whether action list is expanded or collapsed */
  isExpanded?: boolean;
  /** Function that updates isExpanded value */
  setIsExpanded?: (expanded: boolean) => void;
} & HTMLAttributes<HTMLDivElement>;

const ActionListMenu = React.forwardRef<HTMLDivElement, ActionListMenuProps>(
  function ActionListMenu(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { children, setIsExpanded = () => {}, isExpanded, buttonRef, ...props },
    menuRef,
  ) {
    const handleClick = () => {
      /** Close menu when list item is clicked */
      if (isExpanded) {
        setIsExpanded(false);
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
      const { target, key, shiftKey } = e;
      const tabbables = tabbable((menuRef as $TSFixMe).current);
      const firstFocusableEl = tabbables[0];
      const lastFocusableEl = tabbables[tabbables.length - 1];
      switch (key) {
        case ' ': {
          if (
            target instanceof HTMLElement &&
            (target.tagName === 'A' || target.tagName === 'BUTTON')
          ) {
            /** This will prevent scrolling on the page when a link is focused. */
            e.preventDefault();
          }
          break;
        }
        case 'ArrowDown': {
          e.preventDefault();
          /** Focus to first element after it reaches last item */
          if (document.activeElement === lastFocusableEl) {
            firstFocusableEl.focus();
          } else {
            const index = tabbables.indexOf(
              document.activeElement as HTMLElement,
            );
            tabbables[index + 1].focus();
          }
          break;
        }
        case 'ArrowUp': {
          e.preventDefault();
          /** Focus to last element after it reaches first item */
          if (document.activeElement === firstFocusableEl) {
            lastFocusableEl.focus();
          } else {
            const index = tabbables.indexOf(
              document.activeElement as HTMLElement,
            );
            if (index === -1) {
              // In case focus gets lost or user clicks a disabled item
              lastFocusableEl.focus();
            } else {
              tabbables[index - 1].focus();
            }
          }
          break;
        }
        case 'Tab': {
          if (!shiftKey) {
            /** When tabbing, focus to first element after it reaches last item */
            if (document.activeElement === lastFocusableEl) {
              firstFocusableEl.focus();
              e.preventDefault();
            }
          } else {
            /** Focus to last element after it reaches first item when pressing shift+key*/
            if (document.activeElement === firstFocusableEl) {
              lastFocusableEl.focus();
              e.preventDefault();
            }
          }
          break;
        }

        case 'Escape': {
          /** Close popover on ESC*/
          e.preventDefault();
          setIsExpanded(false);
          break;
        }
        default:
          break;
      }
    };

    return (
      <div
        className={stylesheet.menu}
        onKeyDown={handleKeyDown}
        onClick={handleClick}
        ref={menuRef}
        {...props}
      >
        {children}
      </div>
    );
  },
);

export type ActionListProps = {
  /** Consumes `ActionMenu` and `ActionItem` components */
  children: React.ReactNode;
  /** Makes the button unclickable */
  disabled?: boolean;
  /** Type of icon for IconButton component */
  icon?: React.ReactNode;
  /** Toggle content for Button and label attribute for IconButton */
  label: React.ReactNode;
  /** Triggers when the input is blurred. */
  onBlur?: React.FocusEventHandler<HTMLElement>;
  /** Triggers when the menu is opened. */
  onOpen?: () => void;
  /** The default placement of the menu, passed along to Popup */
  placement?: 'bottom' | 'bottom-start' | 'bottom-end';
  /** Standard is for the most common use cases. Passive is mostly used for binary actions or a list of equally weighted primaries. */
  purpose?: 'standard' | 'passive';
  /** The different variants of a Button */
  type?: 'primary' | 'secondary' | 'tertiary' | 'inline';
} & HTMLAttributes<HTMLElement>;

/** An Action List is a button that can have multiple actions taken from a pop-over menu on click.
 * These actions may be buttons or links. This should be able to be styled multiple-ways, not just as the
 * secondary button style. */
const ActionList = React.forwardRef<HTMLElement, ActionListProps>(
  function ActionList(
    {
      children,
      disabled = false,
      label,
      icon,
      onBlur = () => {},
      type = 'secondary',
      onOpen = () => {},
      placement = 'bottom-start',
      purpose = 'standard',
      ...props
    },
    forwardedRef,
  ) {
    const buttonId = useId();
    const buttonRef = useRef<HTMLElement | null>(null);
    const menuRef = useRef<HTMLDivElement | null>(null);
    const lastCalledRef = useRef(false);

    const shouldSkipBlur = useRef(false);
    const isMounted = useRef(false);
    const ref = useJoinedRef(forwardedRef, buttonRef);
    const [isExpanded, setIsExpanded] = useState(false);

    /** Set Trigger component */
    type AnyButton = typeof TextButton & typeof Button & typeof IconButton;
    let Trigger = Button as AnyButton;

    if (type === 'inline') {
      Trigger = TextButton as AnyButton;
    }

    if (icon) {
      Trigger = IconButton as AnyButton;
    }

    const handleClick = () => {
      setIsExpanded((prevValue) => !prevValue);
    };

    useEffect(() => {
      isMounted.current = true;
      return () => {
        isMounted.current = false;
      };
    }, []);

    useEffect(() => {
      /** Focus first list item when menu is opened */
      if (isExpanded) {
        const trigger = buttonRef.current;
        const tabbables = menuRef.current ? tabbable(menuRef.current) : [];
        if (tabbables.length) {
          tabbables[0].focus();
        }

        return () => {
          if (isMounted.current && trigger) {
            trigger.focus();
          }
        };
      }
    }, [isExpanded]);

    useEffect(() => {
      if (!lastCalledRef.current && isExpanded) {
        onOpen();
        lastCalledRef.current = true;
      }
      if (!isExpanded) {
        lastCalledRef.current = false;
      }
    }, [isExpanded, onOpen]);

    const containerRef = useRef<HTMLDivElement | null>(null);

    return (
      <div
        ref={containerRef}
        className={stylesheet.root}
        /** Set skip blur value when focused with keypress */
        onBlur={chainHandlers(onBlur, () => {
          shouldSkipBlur.current = false;
          requestAnimationFrame(() => {
            /** Skip blur if trigger is focused with keypress */
            if (shouldSkipBlur.current) {
              shouldSkipBlur.current = false;
            } else if (isMounted.current) {
              setIsExpanded(false);
            }
          });
        })}
        onFocus={() => {
          shouldSkipBlur.current = true;
        }}
      >
        <Trigger
          id={buttonId}
          onClick={handleClick}
          disabled={disabled}
          /**Don't apply the style to IconButton */
          className={cx(
            {
              [stylesheet.triggerIconWithLabel]: !icon,
              [stylesheet[purpose]]: purpose !== 'standard',
            },
            stylesheet[type],
          )}
          aria-expanded={isExpanded}
          type={type === 'inline' ? undefined : type}
          icon={icon ? icon : undefined}
          label={label ? label : undefined}
          ref={ref}
          aria-haspopup="true"
          {...props}
        >
          <React.Fragment>
            {label}
            <MenuDownIcon className={stylesheet.icon} />
          </React.Fragment>
        </Trigger>

        <Animate
          component={Popup}
          toggle={isExpanded}
          // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; component: ForwardRefEx... Remove this comment to see the full error message
          disabled={disabled}
          className={cx(
            stylesheet.popup,
            { [stylesheet[purpose]]: purpose !== 'standard' },
            stylesheet[type],
          )}
          offset={8}
          placement={placement}
          targetRef={buttonRef}
        >
          <ActionListMenu
            aria-labelledby={buttonId}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            buttonRef={buttonRef}
            ref={menuRef}
          >
            {children}
          </ActionListMenu>
        </Animate>
      </div>
    );
  },
);

export { ActionList as default, ActionListItem };