import React, { useCallback } from 'react';
import cx from 'classnames';

import { ArrowRightIcon, CrossIcon } from '@mc/wink-icons';
import useBreakpoints from '@mc/hooks/useBreakpoints';
import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';
import StackLayout from '../StackLayout';
import IconButton from '../IconButton';
import TextButton from '../TextButton';
import Portal from '../Portal';
import Text from '../Text';
import stylesheet from './AnnouncementCard.less';

export type AnnouncementCardProps = {
  className?: string;
  description: string;
  imgSrc: string;
  linkTarget?: '_self' | '_blank' | '_parent' | '_top';
  linkTitle?: string;
  linkUrl?: string;
  onClose: $TSFixMeFunction;
  onLinkClick?: $TSFixMeFunction;
  title: string;
  tunnelId?: string;
};

const AnnouncementCard = React.forwardRef<$TSFixMe, AnnouncementCardProps>(
  function AnnouncementCard(
    {
      className,
      description,
      imgSrc,
      linkUrl,
      linkTarget,
      linkTitle,
      onClose,
      onLinkClick,
      title,
      tunnelId = 'portal-announcement',
      ...rest
    },
    forwardedRef,
  ) {
    const classList = cx(stylesheet.root, className);
    const { isPhone } = useBreakpoints();

    // Translate default text
    const dismissText = useDsTranslateMessage({
      id: 'mcds.announcement_card.dismiss_text',
      defaultMessage: 'Dismiss announcement card',
    });

    const mountNode = useCallback(() => {
      return document.getElementById(tunnelId);
    }, [tunnelId]);

    const announcement = (
      <StackLayout className={classList} ref={forwardedRef} {...rest}>
        {onClose && (
          <div className={stylesheet.closeIcon}>
            <IconButton
              label={dismissText}
              icon={<CrossIcon />}
              onClick={onClose}
            />
          </div>
        )}
        <StackLayout gap={6}>
          {!isPhone && imgSrc && (
            <div className={stylesheet.image}>
              <img src={imgSrc} alt={title} />
            </div>
          )}
          <div>
            {title && (
              <Text appearance={isPhone ? 'medium' : 'large'}>{title}</Text>
            )}
            {description && (
              <Text as="span" appearance="small-secondary">
                {description}
              </Text>
            )}
          </div>
          {linkTitle && linkUrl && (
            <TextButton
              href={linkUrl}
              onClick={onLinkClick ? onLinkClick : onClose}
              target={linkTarget}
            >
              {linkTitle} <ArrowRightIcon />
            </TextButton>
          )}
        </StackLayout>
      </StackLayout>
    );

    return <Portal mountNode={mountNode}>{announcement}</Portal>;
  },
);

export default AnnouncementCard;
