import React, { useEffect, useRef } from 'react';
import cx from 'classnames';

import useJoinedRef from '@mc/hooks/useJoinedRef';
import Portal from '../Portal';
import { inertExcludeSelectors } from './inertExclude';
import hideAppLevelElements from './ariaHider';
import stylesheet from './Dialog.less';

export type DialogProps = {
  children?: React.ReactNode;
  className?: string;
  onRequestClose: $TSFixMeFunction;
};

const Dialog = React.forwardRef<$TSFixMe, DialogProps>(function (
  { children, className, onRequestClose, ...rest },
  forwardedRef,
) {
  const dialogRef = useRef();
  const ref = useJoinedRef(forwardedRef, dialogRef);

  useEffect(() => {
    // Focus management
    const previousFocus = document.activeElement;
    const dialogEl = dialogRef.current;
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    dialogEl.focus();
    // Hide elements
    // TODO: We should ensure this only happens once across all modals.
    const popoverEls = [
      ...document.querySelectorAll('.mcds-popup-portal-root'),
    ];
    // This hides elements based on the selectors in the inertExclude.js file
    const inertExcludeEls = inertExcludeSelectors.map((selector) =>
      document.querySelector(selector),
    );
    // ProseMirror uses `MutationObserver` on its content to detect
    // changes to the html element it manages. The `inert` polyfill causes attribute
    // changes to occur on elements inside the ProseMirror container.
    // ProseMirror has a schema that defines what are valid element and attributes so it
    // attempts fix them. When there's more than 1 child element on the ProseMirror container
    // that the `inert` polyfill mutates, it causes an infinite loop between these two
    // `MutationObserver`. For now, we will ignore the content of ProseMirror.
    // This can be deleted if the `inert` polyfill is no longer used.
    const proseMirrorContainers = [
      ...document.querySelectorAll('.ProseMirror'),
    ];
    const ignoreElements = [
      dialogEl,
      ...popoverEls,
      ...proseMirrorContainers,
      ...inertExcludeEls,
    ];
    const unhideAppLevelElements = hideAppLevelElements(ignoreElements);
    return () => {
      unhideAppLevelElements();
      // The `inert` polyfill uses a `MutationObserver` to detect changes to
      // the `inert` attribute. We must let the current task end before
      // focusing the previous element. Check if we use the inert polyfill
      // (Element.prototype.inert on polyfill.mailchimp.com) before removing.
      setTimeout(() => {
        (previousFocus as $TSFixMe).focus();
      }, 0);
    };
  }, []);

  return (
    <Portal className="wink-dialog-portal-root">
      <div
        className={cx(stylesheet.dialog, className)}
        onKeyDown={(event) => {
          // @ts-expect-error TS(2339) FIXME: Property 'tagName' does not exist on type 'EventTa... Remove this comment to see the full error message
          const { tagName: focusedElementType } = event.target;
          if (
            !event.defaultPrevented &&
            event.key === 'Escape' &&
            focusedElementType !== 'INPUT' &&
            focusedElementType !== 'TEXTAREA' &&
            focusedElementType !== 'SELECT'
          ) {
            onRequestClose();
            event.preventDefault();
          }
        }}
        role="dialog"
        tabIndex={-1}
        ref={ref}
        {...rest}
      >
        {children}
      </div>
    </Portal>
  );
});

export default Dialog;
