import React, { PropsWithChildren } from 'react';
import cx from 'classnames';
import stylesheet from './Heading.less';

export type HeadingProps = PropsWithChildren<{
  className?: string;
  level: 1 | 2 | 3 | 4 | 5 | 6;
  appearance?:
    | 'heading-1'
    | 'heading-display'
    | 'heading-2'
    | 'heading-3'
    | 'heading-4'
    | 'heading-5'
    | 'heading-6';
  textWrap?: 'wrap' | 'nowrap' | 'balance' | 'pretty';
}>;

type SupportedHeading = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

/**
 * Many screen reader users navigate by heading. Add headings for each section
 * of the page, even if they must be visually hidden — as a screen reader user,
 * I want to be able to navigate through each section easily.
 */
const Heading: React.ForwardRefRenderFunction<
  HTMLHeadingElement,
  HeadingProps
> = function (
  {
    children,
    className,
    level,
    appearance = `heading-${level}`,
    textWrap,
    ...props
  },
  forwardedRef,
) {
  const H = `h${level}` as SupportedHeading;
  return (
    <H
      className={cx(
        stylesheet[appearance],
        className,
        stylesheet.root,
        textWrap && stylesheet[`text-${textWrap}`],
      )}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </H>
  );
};

export default React.forwardRef(Heading);
